import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getDefaultNormalizer } from "@testing-library/react";
import toast from "react-hot-toast";
import { loginUser, useAuthState, useAuthDispatch } from "../Context";
import { Header } from "../components/Navbar";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAuthDispatch();
  const { loading, errorMessage, userDetails } = useAuthState(); //read the values of loading and errorMessage from context

  useEffect(() => {
    if (userDetails != "") {
      navigate("/home");
    }
  }, []);

  const onSaveHandler = async (e) => {
    e.preventDefault();
    try {
      let payload = { email, password };

      let response = await loginUser(dispatch, payload); //loginUser action makes the request and handles all the neccessary state changes
      if (!response.token) return;
      toast.success("login successfull");
      navigate("/home");
    } catch (error) {
      console.log(error);
      toast.error("Invalid credentials");
    }
  };
  return (
    <>
      <Header />

      <div
        className="main"
        // style={{ backgroundImage: `url("im.jpg")`, width: "100%" }}
      >
        <form
          onSubmit={onSaveHandler}
          className="login"
          style={{ border: "1px solid grey" }}
        >
          <h1 className="label">Login</h1>
          <input
            className="input"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Enter Email"
            name="email"
            id="email"
            required
          />
          <input
            className="input"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter Password"
            name="psw"
            id="psw"
            required
          />

          <button
            type="submit"
            style={{
              padding: "2vmin",
              backgroundColor: "#000428",
              color: "white",
              textTransform: "uppercase",
            }}
            className="registerbtn"
          >
            Login
          </button>

          <button
            style={{
              backgroundColor: "#f1f1f1",
              textAlign: "center",
              widh: "100%",
              padding: "2vmin",
              fontFamily: "Raleway",
              fontWeight: "normal",
              cursor: "pointer",
              border: "none",
            }}
            onClick={() => navigate("/forgot")}
          >
            Forgot Password
          </button>
        </form>
      </div>
    </>
  );
};
