import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import backend from "../api";

function File() {
  const [serial, setSerial] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { data },
      } = await backend.get(`/user?serialId=${serial}`);
      if (data.length <= 0) {
        toast.error("Student not found");
      } else {
        navigate(`/print/${data[0]._id}`);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Invalid Serial ID");
    }
  };

  return (
    <div style={{}} className="main">
      <form
        style={{
          justifyContent: "center",
          height: "300px",
          backgroundColor: "lightgrey",
        }}
        className="login"
        onSubmit={handleSubmit}
      >
        <h3>Please Enter your Key </h3>
        <input
          onChange={(e) => {
            setSerial(e.target.value);
          }}
          type="text"
          placeholder="Enter your key"
        ></input>
        <button
          type="submit"
          style={{ fontFamily: "Raleway", fontSize: 20 }}
          class="registerbtn"
        >
          Find
        </button>
      </form>
    </div>
  );
}

export default File;
