import "./App.css";
import { Login } from "./Admin/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Forgot } from "./Admin/Forgot";
import { Home } from "./Admin/Home";
import { Newuser } from "./Admin/Newuser";
import { User } from "./Admin/User";
import { AuthProvider } from "./Context";

// import { store } from './store';
import toast, { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import "react-responsive-modal/styles.css";
import File from "./Admin/File";
import PrintQR from "./Admin/PrintQR";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App">
      <Toaster />
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/newuser" element={<Newuser />} />
            <Route path="/user" element={<User />} />
            <Route path="/file" element={<File />} />
            <Route exact path="/print/:id" element={<PrintQR />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
