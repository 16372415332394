import React, { useEffect, useRef, useCallback } from "react";
import "./css/certificate.css";
import QRCodeStyling from "qr-code-styling";
import { toPng, toJpeg } from 'html-to-image'

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  dotsOptions: {
    color: "#44a3ff",
    type: "classy-rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20,
  },
});

const PrintLayout = ({ rowData }) => {
  const ref = useRef(null);
  const [url, setUrl] = React.useState(rowData.file ? rowData.file : "");

  useEffect(() => {
    qrCode.update({
      data: url,
      image:
        "https://image-assets-bucket.s3.ap-south-1.amazonaws.com/grade4-1622807419918.png",
    });
  }, [url]);

  const downloadPng = useCallback(() => {
    if (ref.current === null) {
      return
    }
    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        console.log(dataUrl)
        const link = document.createElement('a')
        link.download = `${rowData.serialId.split("-")[0]}`
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref]);

  return (
    <>
      <div
        ref={ref}
        style={{ height: "55vh" }}
        className="mainr pagecut pt-5 text-center pb-5 pl-1 pr-1 d-flex flex-column justify-content-center"
      >
        <div className="row pl-3 pr-3" style={{ backgroundColor: "white" }}>
          <div
            style={{ height: "60vh" }}
            className="mt-2 pt-2 text-center pl-3 pr-3 d-flex flex-column justify-content-center align-items-start"
          >
            {rowData.qr ? (
              <>
                <div style={{ border: "2px solid black", width: "250px" }}>
                  <img
                    src={rowData.qr}
                    alt="QR"
                    style={{
                      // padding: ",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>
                <span>Ministry of Foreign Affairs, Pakistan.</span>
                <h3 className="px-5">{rowData.serialId.split("-")[0]}</h3>
              </>
            ) : (
              <h3>Please upload qr for this user first!</h3>
            )}
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          downloadPng()
        }}
      >
        Export as PNG
      </button>
    </>
  );
};

export default PrintLayout;
