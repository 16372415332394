import React from "react";
import "./css/footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="pl-5 pr-5" style={{ color: "white" }}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <ul className="list-inline social-buttons">
            <li className="list-inline-item">
              {/* <a href="www.fb.com" rel="noopener noreferrer" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a> */}
            </li>
          </ul>

          <div style={{ color: "white" }}>&copy; Copyright-2022 </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
