import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
export const Newuser = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [rollno, setRollno] = useState("");
  const [file, setFile] = useState("");

  const onSaveHandler = (e) => {
    e.preventDefault();
    navigate("/home");
  };

  return (
    <div className="new">
      <form onSubmit={onSaveHandler} className="form">
        <h1 style={{ color: "blue", fontFamily: "Raleway", width: "100%" }}>
          Add New User
        </h1>

        <input
          className="text"
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Enter Name"
          name="email"
          id="email"
          required
        />
        <input
          className="text"
          onChange={(e) => setRollno(e.target.value)}
          type="text"
          placeholder="Enter Roll no"
          name="rollno"
          id="psw"
          required
        />
        <input
          className="text"
          onChange={(e) => setFile(e.target.value)}
          type="file"
          placeholder="choose file"
          name="file"
          id="psw"
          required
        />
        <button
          type="submit"
          style={{
            fontFamily: "Raleway",
            fontSize: "20px",
            width: "100px",
            borderRadius: "1rem",
            marginTop: "1rem",
            justifyContent: "center",
            marginRight: "80%",
          }}
          class="registerbtn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};
