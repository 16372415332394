import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backend from "../api";
// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";

import toast from "react-hot-toast";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

const PrintQR = () => {
  const { id } = useParams();
  const [user, setUser] = React.useState();
  const navigate = useNavigate();
  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const {
        data: { data },
      } = await backend.get(`/user?_id=${id}`);
      if (data[0].file) {
        setUser(data[0]);
      } else {
        toast.error("file not found");
        navigate("/");
      }
    } catch (err) {
      toast.error("file not found");
      navigate("/");
    }
  };

  const onImageDownload = () => {
    htmlToImage
      .toPng(document.getElementById("QR"))
      .then(function (dataUrl) {
        download(dataUrl, "QR.png");
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <>
      {user ? (
        <div style={{ width: "800px", margin: "0 auto" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
            }}
            id="QR"
          >
            <QRCode
              imageSettings={{
                src: "https://picsum.photos/id/67/300/300",
                excavate: true,
              }}
              includeMargin
              fgColor="#7f0000"
              size={255}
              value={user?.file}
            ></QRCode>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              //   alignItems: "end",
            }}
          >
            <button
              style={{
                fontFamily: "Raleway",
                fontSize: "20px",
                maxWidth: "200px",
              }}
              className="registerbtn"
              onClick={() => {
                navigate("/");
              }}
            >
              Go Back
            </button>
            <button
              style={{
                fontFamily: "Raleway",
                fontSize: "20px",
                maxWidth: "200px",
              }}
              className="registerbtn"
              onClick={() => {
                onImageDownload();
              }}
            >
              Print
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrintQR;
