import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./css/nav.css";
import { logout, useAuthDispatch, useAuthState } from "../Context";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const { loading, errorMessage, userDetails } = useAuthState(); //read the values of loading and errorMessage from context

  const handleLogout = () => {
    logout(dispatch);
    navigate("/");
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{
        background: "linear-gradient(-90deg, #170a2a 55%, #86c2c2 )",
        padding: "13px",
      }}
      variant="dark"
      sticky="top"
      className="mainNav"
    >
      <Navbar.Brand className="brand ml-3" href="#top">
        <div className="row d-flex flex-row align-items-center">
          {/* <img
            className="img-fluid ml-3 mr-3"
            //   src={logo}
            style={{ maxWidth: "7%", height: "auto" }}
            alt="Logo"
          /> */}
          Logo
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="nav-link ml-auto text-uppercase">
          {localStorage.getItem("currentUser") ? (
            <>
              <Link className="nav-link" to={{ pathname: "/home" }}>
                Dashboard
              </Link>
              <Link
                className="nav-link"
                to={{ pathname: "/" }}
                onClick={() => handleLogout()}
              >
                Logout
              </Link>
            </>
          ) : (
            <Link
              className="nav-link"
              to={{ pathname: "/" }}
              onClick={() => handleLogout()}
            >
              Login
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
